'use strict';

import '@fortawesome/fontawesome-free/js/fontawesome';

import $ from "jquery";
import modaal from "modaal";

$(".modal").modaal();

$(function () {
  $('#nav-toggle,nav a').on('click', function () {
    $('body').toggleClass('open');
  })
});
